import React, { Component } from 'react';
import styled from 'styled-components';

/**
 * Customizable YoungButton
 * Props:
 *  - text: text displayed into the button
 *  - style: styled const (see utils.js)
 *  - active: active callback and apply style
 *  - callback: callback to be executed when YoungButton is clicked


 */
class FormButton extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    if (this.props.style) {
      const Button = this.props.style;

      return (
        <Button
          className={this.props.active ? 'active' : ''}
          onClick={this.onClick}
        >
          {this.props.text}
        </Button>
      );
    } else {
      return (
        <button
          className={this.props.active ? 'active' : ''}
          onClick={this.onClick}
        >
          {this.props.text}
        </button>
      );
    }
  }

  onClick = () => {
    if (this.props.callback !== undefined) {
      if (this.props.active) this.props.callback();
    }
  };
}

export default FormButton;
