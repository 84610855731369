import styled from "styled-components"
import React, { Component } from "react"
import { translate, Trans } from "react-i18next"

import bodyStyles from "./bodyStyles"

class LoginBody extends Component {
  render() {
    let YoungBody = styled.div`
      ${bodyStyles};
      ${this.props.styles};
    `
    return <YoungBody>{this.props.content}</YoungBody>
  }
}
export default LoginBody
