import { css } from 'styled-components';
import * as utils from '../../GlobalStyles/utils';

let headerStyles = css`
  height: auto;
  min-height: 100vh;
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  ${utils.marginH('50px')};
  ${utils.flexCenter};
  ${utils.media
    .tablet`margin-left:5%;margin-right:5%;min-height:calc(100vh - 60px)`};
`;
export default headerStyles;
