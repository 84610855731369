import React from "react"
import ConfirmComponent from "../components/login/confirm.component"
import { graphql } from "gatsby"
import { useI18next, I18nextContext } from "gatsby-plugin-react-i18next"
import AuthLayout from "../components/login/layout"
import Seo from "../components/seo"
const Confirm = ({ pageContext: { i18n } }) => {
  const { t } = useI18next()
  const context = React.useContext(I18nextContext)
  const lng = context.language
  return (
    <AuthLayout>
      <Seo
        seoIdentifier="/confirm"
        originalPath={i18n.originalPath}
        lang={lng}
        title={t("confirm_email.seo.title")}
        description={t("confirm_email.seo.description")}
      />
      <ConfirmComponent t={t} />
    </AuthLayout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Confirm
