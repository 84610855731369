import React, { Component } from "react"
import Header from "./header/header.component"
import LoginBody from "./body/body.component"
import { translate } from "react-i18next"
import styled from "styled-components"
import * as utils from "../GlobalStyles/utils"
import FormButton from "../form-button/form-button.component"
import LastStepImg from "../../images/lastStep.svg"
import ErrorImg from "../../images/registrationError.svg"
import Logo from "../../images/Logo.svg"
import { Link } from "gatsby"
import { BodyText } from "../texts"
import Message from "../Message/message.component"
import Messages from "../Message/message.data"

import Loader from "../loader/loader.component"
// import { StyleLinkLogo } from '../GlobalStyles/utils';
import PlayStoreButton from "../stepdrop/basic-components/play-store-button.component"
import AppStoreButton from "../stepdrop/basic-components/app-store-button.component"

const Container = styled.div`
  font-family: Grotesk Bold, Normal;
  min-height: 100vh;
  min-width: 100vw;
  background: black;
  .form-style {
    width: min(90vw, 367px);
    margin: 0 auto;

    @media screen and (max-width: 768px) {
      padding: 0;
    }
  }
`

const HeaderContainer = styled.div`
  display: none;

  @media only screen and (min-width: 769px) {
    display: block;
  }
`

const IMGLogo = styled.img`
  height: 100%;
  object-fit: contain;
  ${utils.media.tablet`padding-left:10px;height:50%`};
`

const FormButtonStyled = styled.button`
  ${utils.formButton};
  margin-top: 40px;
`

let Content = styled.div`
  ${utils.flexCenter};
  ${utils.flexColumn};
  width: 400px;
  max-width: 90%;
  ${utils.media.desktop`padding-bottom:30px;`};
`

const IMG = styled.img`
  object-fit: contain;
  width: 100px;
  height: 100px;
  margin-bottom: 30px;
`
let id = ""
let code = ""
class Confirm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      emailConfirmed: false,
      loading: true,
      OS: "Unknown",
    }
    // let url = this.props.location.search
    id = "" //params.userId;
    code = "" //params.code;
    ////console.log(params);
  }

  getMobileOperatingSystem = () => {
    var userAgent =
      typeof window != "undefined" &&
      (navigator.userAgent || navigator.vendor || window.opera)

    if (typeof window != "undefined" && userAgent.match(/iPhone/i)) {
      this.setState({ OS: "iOS" })
    } else if (typeof window != "undefined" && userAgent.match(/Android/i)) {
      this.setState({ OS: "Android" })
    } else {
      this.setState({ OS: "Unknown" })
    }
  }

  confirmEmail = (userId, code) => {
    let user = {
      UserId: userId,
      Code: code,
    }
    var formBody = []
    for (var property in user) {
      var encodedKey = encodeURIComponent(property)
      if (property !== "Code") {
        var encodedValue = encodeURIComponent(user[property])
      } else {
        var encodedValue = user[property]
      }
      formBody.push(encodedKey + "=" + encodedValue)
    }
    formBody = formBody.join("&")

    fetch(process.env.GATSBY_IDENTITY_URL + "/api/account/confirmemail", {
      method: "POST",
      headers: {
        Accept: "application/x-www-form-urlencoded",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
    })
      .then(response => {
        if (response.status === 200) {
          this.setState({
            emailConfirmed: true,
            loading: false,
          })
          return 0
        } else {
          this.setState({
            loading: false,
          })
          return response.json()
        }
      })
      .then(result => {
        if (
          result["error_description"] !== null &&
          result["error_description"] !== undefined
        ) {
          switch (result["error_description"]) {
            case "ERR.USER_NOT_EXISTS" || "ERR.INVALID_CODE":
              this.addMessage(Messages.userNotExists)
              break
            case "ERR.ALREADY_CONFIRMED":
              this.addMessage(Messages.alreadyConfirmed)
              break
            default:
              this.addMessage(Messages.genericError)
              break
          }
        }
      })
      .catch(error => {
        this.setState({
          loading: false,
        })
        this.addMessage(Messages.genericError)
        console.log(error)
      })
  }

  addMessage = msg => {
    if (!this.state.data.includes(msg)) {
      var newArray = this.state.data.slice()
      newArray.push(msg)
      this.setState(() => ({
        data: newArray,
      }))
      setTimeout(
        function () {
          this.setState(prevState => ({
            data: prevState.data.filter(el => el != msg),
          }))
        }.bind(this),
        3000
      )
    }
  }

  componentWillMount() {
    this.getMobileOperatingSystem()
  }

  componentDidMount() {
    const userId = this.getQueryParam("userId")
    const code = this.getQueryParam("code")
    this.confirmEmail(userId, code)
  }
  getQueryParam = param => {
    var result = window.location.search.match(
      new RegExp("(\\?|&)" + param + "(\\[\\])?=([^&]*)")
    )
    return result ? result[3] : false
  }
  render() {
    const { t } = this.props
    let content = (
      <Content>
        <Message data={this.state.data} />
        {this.state.loading ? (
          <Loader />
        ) : (
          <IMG
            alt=""
            src={this.state.emailConfirmed ? LastStepImg : ErrorImg}
          />
        )}

        <BodyText color={"rgb(160, 161, 163)"}>
          {this.state.loading
            ? ""
            : this.state.emailConfirmed
            ? t("cofirm_email.success")
            : t("cofirm_email.error")}
        </BodyText>

        {!this.state.emailConfirmed ? (
          <Link
            to="/login"
            style={{
              ...utils.StyleLink,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <FormButton
              text={t("buttons.return_to_login")}
              style={FormButtonStyled}
              active={true}
            />
          </Link>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            {(this.state.OS == "Android" || this.state.OS == "Unknown") && (
              <PlayStoreButton style={{ background: "#00C789" }} />
            )}
            {(this.state.OS == "iOS" || this.state.OS == "Unknown") && (
              <AppStoreButton style={{ background: "#E81F76" }} />
            )}
          </div>
        )}
      </Content>
    )

    return (
      <Container>
        {/* <MetaTags>
          <title>SignUp - Young Platform</title>
        </MetaTags> */}
        <LoginBody content={content} />
      </Container>
    )
  }
}

export default Confirm
