import React, { Component } from 'react';

class Loader extends Component {
  render() {
    return (
      <div id="preloader" style={this.props.style}>
        <div className="pre_container">
          <div className="loader_7" />
        </div>
      </div>
    );
  }
}

export default Loader;
