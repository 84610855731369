import React, { Component } from "react"
import { translate } from "react-i18next"
import { TitleText } from "../../texts"
import styled from "styled-components"
import Logo from "../images/play-store.svg"
const Container = styled.div`
  width: 207px;
  height: 56px;
  background: var(--green);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  cursor: pointer;
  margin: 10px;
  @media screen and (max-width: 768px) {
    height: 44px !important;
    width: 145px !important;
    margin: 5px !important;
    > img {
      width: 15px !important;
      margin-right: 10px !important;
    }
    > span {
      font-size: 14px !important;
    }
  }
`

class PlayStoreButton extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { t } = this.props

    return (
      <Container
        style={this.props.style}
        onClick={() =>
          window.open(
            "https://play.google.com/store/apps/details?id=com.youngplatform.young&referrer=utm_source%3Dstepdrop-site%26utm_medium%3Dsite_redirect%26utm_campaign%3Dsites-traffic"
          )
        }
      >
        <img
          alt="playstore"
          style={
            this.props.header
              ? { marginRight: "15px", width: "14px" }
              : { marginRight: "15px" }
          }
          src={Logo}
        />
        <TitleText
          size="16px"
          size={this.props.header ? "14px" : "16px"}
          color="white"
          style={{ whiteSpace: "normal" }}
        >
          Play Store
        </TitleText>
      </Container>
    )
  }
}

export default PlayStoreButton
